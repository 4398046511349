import axios from 'axios';
import { ref, computed, watchEffect } from 'vue';
import client from './client';
import contacts from './contacts';
import ticket from './ticket';
import ticketNotes from './ticketNotes';
import openTickets from './openTickets';
import settings from './settings';
import router from '../router/index';

//const serverString = 'http://localhost:3000/server/';
const serverString = 'https://app.hertzlertech.com/server/';

const intState: any = {
  isLoading: true,
  currentUserId: '',
  users: [],
  loginInput: {
    username: '',
    password: '',
  },
  alert: {
    showAlert: false,
    alertMessage: '',
    denyMessage: '',
    confirmMessage: '',
    callback: '',
    callbackInfo: '',
  },
};

const state = ref(intState);
const loginErr = ref('');

const currentUser = computed(() => {
  return state.value.users.filter((v: any) => v.userid == state.value.currentUserId)[0];
});

const toggleLoading = function(bool: boolean) {
  state.value.isLoading = bool;
};

const setAlert = (
  alertMessage: string,
  denyMessage: string,
  confirmMessage: string,
  callback: string,
  callbackInfo: string
) => {
  state.value.alert.confirmMessage = confirmMessage;
  state.value.alert.alertMessage = alertMessage;
  state.value.alert.denyMessage = denyMessage;
  state.value.alert.callback = callback;
  state.value.alert.callbackInfo = callbackInfo;
  state.value.alert.showAlert = true;
  document.getElementsByTagName('body')[0].setAttribute('style', 'overflow: hidden;');
};

const closeAlert = () => {
  state.value.alert.showAlert = false;
  state.value.alert.confirmMessage = '';
  state.value.alert.alertMessage = '';
  state.value.alert.denyMessage = '';
  state.value.alert.callback = '';
  state.value.alert.callbackInfo = '';
  document.getElementsByTagName('body')[0].removeAttribute('style');
};

const confirmAlert = () => {
  if (state.value.alert.callback == 'deleteclient') {
    client.deleteClient();
    closeAlert();
  } else if (state.value.alert.callback == 'deletecontact') {
    contacts.deleteContact(state.value.alert.callbackInfo);
    closeAlert();
  } else if (state.value.alert.callback == 'deleteticket') {
    ticket.deleteTicket();
    closeAlert();
  } else if (state.value.alert.callback == 'deletenote') {
    ticketNotes.deleteNote(state.value.alert.callbackInfo);
    closeAlert();
  } else if (state.value.alert.callback == 'deleteuser') {
    settings.deleteUser(state.value.alert.callbackInfo);
    closeAlert();
  }
};

const logoff = () => {
  axios
    .post(serverString + 'logoff')
    .then((response) => {
      if (response.statusText != 'OK') throw 'status not OK';
    })
    .catch((err) => {
      console.log(err);
    });
  state.value.currentUserId = '';
  router.push({ name: 'Login' });
};

const getUsers = () => {
  axios
    .get(serverString + 'users')
    .then((response) => {
      if (response.statusText != 'OK') throw 'status not OK';
      state.value.users = response.data;
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') logoff();
      console.log(err);
    });
};

const login = () => {
  loginErr.value = '';

  axios
    .post(serverString + 'login', {
      username: state.value.loginInput.username,
      password: state.value.loginInput.password,
    })
    .then((response) => {
      if (response.statusText != 'OK') throw 'status not OK';
      if (response.data.status != 'succeded') throw 'status not OK';
      state.value.currentUserId = response.data.userid;
      openTickets.filterBy.value = response.data.userid;
      getUsers();
      router.push({ name: 'Dashboard' });

      state.value.loginInput.username = '';
      state.value.loginInput.password = '';
    })
    .catch((err) => {
      loginErr.value = 'login failed';
      console.log(err);
    });
};

const checkLogin = () => {
  axios
    .get(serverString + 'getcurrentuser')
    .then((res) => {
      state.value.currentUserId = res.data.userid;
      getUsers();
      openTickets.filterBy.value = res.data.userid;
    })
    .catch((err) => {
      logoff();
    });
};

watchEffect(() => {
  if (state.value.currentUserId == '') {
    checkLogin();
  }
});

export default {
  serverString,
  state,
  currentUser,
  loginErr,
  toggleLoading,
  setAlert,
  closeAlert,
  confirmAlert,
  getUsers,
  login,
  logoff,
  checkLogin,
};
