import axios from 'axios';
import { ref, readonly, computed } from 'vue';
import global from './global';

const intState: any = {
  clients: [],
  sortedColumn: 'name',
  sortDesc: false,
  search: '',
};

const state = ref(intState);

const compare = (a: any, b: any) => {
  if (state.value.sortDesc) {
    if (a[state.value.sortedColumn] < b[state.value.sortedColumn]) return 1;
    if (a[state.value.sortedColumn] > b[state.value.sortedColumn]) return -1;
  } else {
    if (a[state.value.sortedColumn] > b[state.value.sortedColumn]) return 1;
    if (a[state.value.sortedColumn] < b[state.value.sortedColumn]) return -1;
  }
  return 0;
};

const sortedClients = computed(() => {
  let newClients = state.value.clients.sort(compare);
  newClients = newClients.filter((v: any) => {
    if (
      Object.values(v)
        .toString()
        .toLowerCase()
        .includes(state.value.search.toLowerCase())
    ) {
      return true;
    }
  });
  return newClients;
});

const getClients = () => {
  axios
    .get(global.serverString + 'clients')
    .then((response) => {
      if (response.statusText != 'OK') throw 'status not OK';
      state.value.clients = response.data;
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      console.log(err);
    });
};

const updateSearch = (newTerms: string) => {
  state.value.search = newTerms;
};

const changeSort = (newDirection: string, overideDesc?: boolean) => {
  if (state.value.sortedColumn == newDirection) {
    state.value.sortDesc = !state.value.sortDesc;
  } else {
    state.value.sortedColumn = newDirection;
    state.value.sortDesc = false;
  }

  if (overideDesc) state.value.sortDesc = false;
};

export default { state: readonly(state), sortedClients, getClients, updateSearch, changeSort };
