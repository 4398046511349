import axios from 'axios';
import { ref, watch, nextTick } from 'vue';
import client from './client';
import { v4 as uuidv4 } from 'uuid';
import global from './global';

const intContacts: Array<any> = [];
const contacts = ref(intContacts);
const contactsSaveStatus = ref(false);
const contactsErrStatus = ref(false);
let shouldWatch = true;

const getContacts = (id: string) => {
  shouldWatch = false;
  contacts.value = [];
  if (id == '' || id == null) {
    shouldWatch = true;
    return;
  }
  axios
    .get(global.serverString + 'contacts', {
      params: {
        id,
      },
    })
    .then((response) => {
      if (response.statusText != 'OK') throw 'status not OK';
      response.data.forEach((v: string, i: number) => {
        for (const key in response.data[i]) {
          if (response.data[i][key] == 'null') response.data[i][key] = '';
        }
      });
      shouldWatch = false;
      contacts.value = response.data;
      contactsSaveStatus.value = true;
      client.handleStatus();
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      console.log(err);
    })
    .finally(() => {
      shouldWatch = true;
    });
};

const addNewContact = (id: string) => {
  contacts.value.push({
    contactid: uuidv4(),
    clientid: id,
    name: '',
    email: '',
    phone: '',
    isprimary: false,
  });
};

const saveContacts = () => {
  axios
    .post(global.serverString + 'updatecontacts', {
      contacts: contacts.value,
    })
    .then((res) => {
      if (res.statusText != 'OK') throw 'status not OK';
      if (res.data.status != 'succeded') throw 'status not OK';
      nextTick(() => {
        contactsErrStatus.value = false;
        contactsSaveStatus.value = true;
        client.handleStatus();
      });
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      contactsErrStatus.value = true;
      client.handleStatus();
      console.log(err);
    });
};

const deleteContact = (id: string) => {
  axios
    .delete(global.serverString + 'deletecontact', {
      data: {
        contactid: id,
      },
    })
    .then((res) => {
      if (res.statusText != 'OK') throw 'status not OK';
      if (res.data.status != 'succeded') throw 'status not OK';
      contacts.value = contacts.value.filter((v) => v.contactid != id);
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      console.log(err);
    });
};

const clearPrimary = (contact: object) => {
  shouldWatch = false;
  contacts.value.forEach((v, i) => {
    if (v != contact) {
      contacts.value[i].isprimary = false;
    }
  });
  shouldWatch = true;
};

watch(
  contacts,
  () => {
    if (shouldWatch) {
      contactsSaveStatus.value = false;
      client.delaySaveClient();
      client.handleStatus();
    }
  },
  { deep: true }
);

export default {
  contacts,
  contactsSaveStatus,
  contactsErrStatus,
  getContacts,
  addNewContact,
  saveContacts,
  deleteContact,
  clearPrimary,
};
