import axios from 'axios';
import { ref, watch, nextTick } from 'vue';
import router from '../router/index';
import global from './global';
import tickets from './tickets';
import ticketNotes from './ticketNotes';

interface TicketDetails {
  [index: string]: string | number | null;
  ticketid: string | null;
  clientid: string | null;
  clientname: string | null;
  contactid: string | null;
  contactname: string | null;
  description: string | null;
  status: number | null;
  datecreated: number | null;
  dateclosed: number | null;
  userid: string | null;
  createdby: string | null;
  assignedid: string | null;
  assignedname: string | null;
  details: string | null;
  priority: number | null;
  ticketnumber: number | null;
  duedate: number | null;
}

const intTicketDetails: TicketDetails = {
  ticketid: '',
  clientid: '',
  clientname: '',
  contactid: '',
  contactname: '',
  description: '',
  status: null,
  datecreated: null,
  dateclosed: null,
  userid: '',
  createdby: '',
  assignedid: '',
  assignedname: '',
  details: '',
  priority: 0,
  ticketnumber: null,
  duedate: null,
};

const ticketDetails = ref(intTicketDetails);
const ticketSaveStatus = ref(false);
const ticketErrStatus = ref(false);
const saveStatus = ref(false);
const errStatus = ref(false);
let timeoutHandle: any = null;
let shouldWatch = true;
let ticketClosed = false;
let ticketReopened = false;

const handleStatus = () => {
  if (ticketErrStatus.value || ticketNotes.noteErrStatus.value) {
    errStatus.value = true;
  } else {
    errStatus.value = false;
  }

  if (ticketSaveStatus.value && ticketNotes.noteSaveStatus.value) {
    saveStatus.value = true;
  } else {
    saveStatus.value = false;
  }
};

const getTicket = (id: string) => {
  axios
    .get(global.serverString + 'ticket', {
      params: {
        id,
      },
    })
    .then((response) => {
      if (response.statusText != 'OK') throw 'status not OK';
      for (const key in response.data[0]) {
        if (response.data[0][key] == 'null') response.data[0][key] = null;
        if (key == 'assignedid' && (response.data[0][key] == null || response.data[0][key] == '')) {
          response.data[0][key] = '';
        }
        if (key == 'priority' && (response.data[0][key] == null || response.data[0][key] == '')) {
          response.data[0][key] = 0;
        }
      }
      shouldWatch = false;
      ticketDetails.value = response.data[0];
      ticketSaveStatus.value = true;
      nextTick(() => {
        shouldWatch = true;
      });
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      console.log(err);
      ticketErrStatus.value = true;
    });
};

const setTicketId = (newId: string) => {
  shouldWatch = false;
  for (const key in ticketDetails.value) {
    if (key == 'assignedid') {
      ticketDetails.value[key] = '';
    } else if (key == 'priority') {
      ticketDetails.value[key] = 0;
    } else if (typeof ticketDetails.value[key] == 'string') {
      ticketDetails.value[key] = '';
    } else if (typeof ticketDetails.value[key] == 'number') {
      ticketDetails.value[key] = null;
    }
  }
  shouldWatch = true;

  if (newId != '') getTicket(newId);
};

const saveTicket = () => {
  if (ticketDetails.value.description == '') return;
  if (ticketDetails.value.ticketid) ticketNotes.saveNotes();
  axios
    .post(global.serverString + 'updateticket', {
      ticketDetails: ticketDetails.value,
      userid: global.state.value.currentUserId,
      ticketClosed,
      ticketReopened,
    })
    .then((res) => {
      if (res.statusText != 'OK') throw 'status not OK';
      if (res.data.status != 'succeded') throw 'status not OK';
      shouldWatch = false;
      if (res.data.ticketid) {
        ticketDetails.value.ticketid = res.data.ticketid;
        history.replaceState({}, '', `/ticket/${res.data.ticketid}`);
      }
      if (res.data.ticketnumber) {
        ticketDetails.value.ticketnumber = res.data.ticketnumber;
      }
      if (res.data.ticketStatus) {
        ticketDetails.value.status = parseInt(res.data.ticketStatus);
      }
      shouldWatch = true;
      ticketErrStatus.value = false;
      ticketSaveStatus.value = true;
      handleStatus();
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      ticketErrStatus.value = true;
      handleStatus();
      console.log(err);
    })
    .finally(() => {
      if (ticketClosed || ticketReopened) {
        ticketNotes.getNotes(ticketDetails.value.ticketid as string);
      }
      ticketClosed = false;
      ticketReopened = false;
    });
};

const delaySaveTicket = () => {
  if (ticketDetails.value.name == '') return;
  window.clearTimeout(timeoutHandle);
  timeoutHandle = window.setTimeout(() => {
    saveTicket();
  }, 1000);
};

const deleteTicket = () => {
  router.push({ name: 'Tickets' });

  axios
    .delete(global.serverString + 'deleteticket', {
      data: {
        ticketid: ticketDetails.value.ticketid,
      },
    })
    .then(() => {
      tickets.getTickets();
    });
};

const completeTicket = () => {
  if (ticketDetails.value.status == 1) {
    ticketDetails.value.status = 0;
    ticketDetails.value.dateclosed = null;
    ticketReopened = true;
    ticketClosed = false;
  } else {
    ticketDetails.value.status = 1;
    ticketDetails.value.dateclosed = Date.now();
    ticketReopened = false;
    ticketClosed = true;
  }
};

const updateClosedDate = (event: { target: HTMLInputElement; }) => {
  const newDate = new Date(event.target.value)
  newDate.setHours(newDate.getHours() + 8)
  ticketDetails.value.dateclosed = newDate.getTime()
}

watch(
  ticketDetails,
  () => {
    if (shouldWatch) {
      ticketSaveStatus.value = false;
      delaySaveTicket();
      handleStatus();
    }
  },
  { deep: true }
);

export default {
  ticketDetails,
  saveStatus,
  errStatus,
  getTicket,
  setTicketId,
  saveTicket,
  deleteTicket,
  completeTicket,
  delaySaveTicket,
  handleStatus,
  updateClosedDate
};
