import { ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import global from '../composables/global';

const showAdd = ref(false);
const confirmBoxUsername = ref('');
const confirmBoxPassword = ref('');
let userid = '';

const cancelAdd = () => {
  showAdd.value = false;
};

const confirmAdd = () => {
  axios
    .post(global.serverString + 'updateuser', {
      userid: userid,
      username: confirmBoxUsername.value,
      password: confirmBoxPassword.value,
    })
    .then((res) => {
      if (res.statusText != 'OK') throw 'status not OK';
      if (res.data.status != 'succeded') throw 'status not OK';
      global.getUsers();
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      console.log(err);
    });
  cancelAdd();
  userid = '';
  confirmBoxUsername.value = '';
  confirmBoxPassword.value = '';
};

const openAdd = (newUserid: string) => {
  if (newUserid == '') {
    userid = uuidv4();
    confirmBoxUsername.value = '';
  } else {
    userid = newUserid;
    confirmBoxUsername.value = global.state.value.users.filter((v: any) => v.userid == newUserid)[0].username;
  }
  confirmBoxPassword.value = '';
  showAdd.value = true;
};

const deleteUser = (userid: string) => {
  axios
    .delete(global.serverString + 'deleteuser', {
      data: {
        userid: userid,
      },
    })
    .then((res) => {
      if (res.statusText != 'OK') throw 'status not OK';
      if (res.data.status != 'succeded') throw 'status not OK';
      global.getUsers();
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      console.log(err);
    });
};

const changeSetting = (event: MouseEvent, setting: string, userid: string) => {
  const target = event.target as HTMLInputElement;
  const targetChecked = target.checked;

  axios
    .post(global.serverString + 'changesetting', {
      userid: userid,
      setting: setting,
      target: targetChecked,
    })
    .then((res) => {
      if (res.statusText != 'OK') throw 'status not OK';
      if (res.data.status != 'succeded') throw 'status not OK';
      global.getUsers();
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      console.log(err);
      global.getUsers();
    });
};

export default {
  showAdd,
  confirmBoxUsername,
  confirmBoxPassword,
  confirmAdd,
  cancelAdd,
  openAdd,
  deleteUser,
  changeSetting,
};
