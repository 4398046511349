import axios from 'axios';
import { watch, ref, nextTick, computed } from 'vue';
import ticket from './ticket';
import global from './global';

interface TicketNote {
  noteid: string;
  ticketid: string;
  userid: string;
  datecreated: number;
  description: string;
  starttime: number | null;
  endtime: number | null;
  paused: number | null;
  issystem: boolean;
}

const ticketNotes = ref<TicketNote[]>([]);
const noteSaveStatus = ref(false);
const noteErrStatus = ref(false);
let shouldWatch = true;

const hovered = ref<null | string>(null);
const selected = ref<null | string>(null);
const preventClose = ref<boolean>(false);

const sortedNotes = computed(() => {
  return ticketNotes.value.sort((a, b) => {
    return a.datecreated > b.datecreated ? 1 : -1;
  });
});

const getNotes = (id: string, dontClearFirst?: boolean) => {
  shouldWatch = false;
  if (!dontClearFirst) ticketNotes.value = [];
  if (id == '' || id == null) {
    shouldWatch = true;
    return;
  }
  axios
    .get(global.serverString + 'notes', {
      params: {
        id,
      },
    })
    .then((response) => {
      if (response.statusText != 'OK') throw 'status not OK';
      shouldWatch = false;
      ticketNotes.value = response.data;
      noteSaveStatus.value = true;
      ticket.handleStatus();
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      console.log(err);
    })
    .finally(() => {
      shouldWatch = true;
    });
};

const saveNotes = () => {
  axios
    .post(global.serverString + 'savenotes', {
      notes: ticketNotes.value,
    })
    .then((res) => {
      if (res.statusText != 'OK') throw 'status not OK';
      if (res.data.status != 'succeded') throw 'status not OK';
      nextTick(() => {
        noteErrStatus.value = false;
        noteSaveStatus.value = true;
        ticket.handleStatus();
      });
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      noteErrStatus.value = true;
      ticket.handleStatus();
      console.log(err);
    });
};

const deleteNote = (id: string) => {
  axios
    .delete(global.serverString + 'deletenote', {
      data: {
        noteid: id,
      },
    })
    .then((res) => {
      if (res.statusText != 'OK') throw 'status not OK';
      if (res.data.status != 'succeded') throw 'status not OK';
      shouldWatch = false;
      ticketNotes.value = ticketNotes.value.filter((v) => v.noteid != id);
      shouldWatch = true;
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      console.log(err);
    });
};

watch(
  sortedNotes,
  () => {
    if (shouldWatch) {
      noteSaveStatus.value = false;
      ticket.delaySaveTicket();
      ticket.handleStatus();
    }
    nextTick(() => {
      const textareas = document.querySelectorAll<HTMLElement>('.note-textarea');
      textareas.forEach((field) => {
        field.style.height = '0px';
        const computed = window.getComputedStyle(field);
        const height = field.scrollHeight;
        field.style.height = height + 10 + 'px';
      });
    });
  },
  { deep: true }
);

export default {
  ticketNotes,
  noteSaveStatus,
  noteErrStatus,
  sortedNotes,
  hovered,
  selected,
  preventClose,
  getNotes,
  deleteNote,
  saveNotes,
};
