<template>
  <NavHeader />
  <Alert v-if="state.alert.showAlert" />
  <Loading v-if="state.isLoading" />
  <router-view v-else />
</template>

<script>
import { ref, watch } from 'vue';
import NavHeader from './components/NavHeader.vue';
import Loading from './views/Loading.vue';
import global from './composables/global';
import Alert from './components/Alert.vue';

export default {
  components: { NavHeader, Loading, Alert },
  setup() {
    global.getUsers();
    return {
      state: global.state,
    };
  },
};
</script>

<style lang="scss" scoped></style>
