import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/global.scss';
import global from './composables/global';

router.beforeEach((to, from, next) => {
  global.toggleLoading(true);
  next();
});

router.afterEach(() => {
  global.toggleLoading(false);
});

createApp(App)
  .use(router)
  .mount('#app');
