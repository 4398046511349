import axios from 'axios';
import { ref, computed } from 'vue';
import global from './global';

const intState: any = {
  openTickets: [],
};

const filterBy = ref('');
const state = ref(intState);

const compare = (a: any, b: any) => {
  if (a.ticketnumber > b.ticketnumber) return 1;
  if (a.ticketnumber < b.ticketnumber) return -1;
  return 0;
};

const sortedTickets = computed(() => {
  const highPriority = state.value.openTickets
    .filter((v: any) => v.priority == 3 && (v.assignedid == filterBy.value || filterBy.value == 'all'))
    .sort(compare);
  const mediumPriority = state.value.openTickets
    .filter((v: any) => v.priority == 2 && (v.assignedid == filterBy.value || filterBy.value == 'all'))
    .sort(compare);
  const lowPriority = state.value.openTickets
    .filter(
      (v: any) => (v.priority == 1 || v.priority == null) && (v.assignedid == filterBy.value || filterBy.value == 'all')
    )
    .sort(compare);
  return [...highPriority, ...mediumPriority, ...lowPriority];
});

const getOpenTickets = () => {
  axios
    .get(global.serverString + 'opentickets')
    .then((response) => {
      if (response.statusText != 'OK') throw 'status not OK';
      state.value.openTickets = response.data;
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      console.log(err);
    });
};

export default { state, sortedTickets, filterBy, getOpenTickets };
