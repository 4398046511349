import axios from 'axios';
import { ref, watch, nextTick } from 'vue';
import router from '../router/index';
import clients from './clients';
import contacts from './contacts';
import global from './global';

const intClientDetails: any = {
  clientid: '',
  name: '',
  addressline1: '',
  addressline2: '',
  city: '',
  state: '',
  zip: '',
  website: '',
  phone: '',
};

const clientDetails = ref(intClientDetails);
const saveStatus = ref(false);
const errStatus = ref(false);
const clientSaveStatus = ref(false);
const clientErrStatus = ref(false);
let timeoutHandle: any = null;
let shouldWatch = true;

const handleStatus = () => {
  if (clientErrStatus.value || contacts.contactsErrStatus.value) {
    errStatus.value = true;
  } else {
    errStatus.value = false;
  }

  if (clientSaveStatus.value && contacts.contactsSaveStatus.value) {
    saveStatus.value = true;
  } else {
    saveStatus.value = false;
  }
};

const getClient = (id: string) => {
  shouldWatch = false;
  axios
    .get(global.serverString + 'client', {
      params: {
        id,
      },
    })
    .then((response) => {
      if (response.statusText != 'OK') throw 'status not OK';
      clientDetails.value = response.data[0];
      for (const key in clientDetails.value) {
        if (clientDetails.value[key] == 'null') clientDetails.value[key] = '';
      }
      clientSaveStatus.value = true;
      handleStatus();
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      console.log(err);
      clientErrStatus.value = true;
    })
    .finally(() => {
      shouldWatch = true;
    });
};

const setClientId = (newId: string) => {
  shouldWatch = false;
  for (const key in clientDetails.value) {
    clientDetails.value[key] = '';
  }
  shouldWatch = true;

  if (newId != '') getClient(newId);
};

const saveClient = () => {
  if (clientDetails.value.name == '') return;
  if (clientDetails.value.clientid) contacts.saveContacts();
  axios
    .post(global.serverString + 'updateclient', {
      clientDetails: clientDetails.value,
    })
    .then((res) => {
      if (res.statusText != 'OK') throw 'status not OK';
      if (res.data.status != 'succeded') throw 'status not OK';
      if (res.data.clientid) {
        clientDetails.value.clientid = res.data.clientid;
        history.replaceState({}, '', `/client/${res.data.clientid}`);
      }
      nextTick(() => {
        clientErrStatus.value = false;
        clientSaveStatus.value = true;
        handleStatus();
      });
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      clientErrStatus.value = true;
      handleStatus();
      console.log(err);
    });
};

const delaySaveClient = () => {
  if (clientDetails.value.name == '') return;
  window.clearTimeout(timeoutHandle);
  timeoutHandle = window.setTimeout(() => {
    saveClient();
  }, 1000);
};

const deleteClient = () => {
  router.push({ name: 'Clients' });

  axios
    .delete(global.serverString + 'deleteclient', {
      data: {
        clientid: clientDetails.value.clientid,
      },
    })
    .then(() => {
      clients.getClients();
    });
};

watch(
  clientDetails,
  () => {
    if (shouldWatch) {
      clientSaveStatus.value = false;
      delaySaveClient();
      handleStatus();
    }
  },
  { deep: true }
);

export default {
  clientDetails,
  saveStatus,
  errStatus,
  getClient,
  setClientId,
  saveClient,
  deleteClient,
  delaySaveClient,
  handleStatus,
};
