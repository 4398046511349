<template>
  <header>
    <img class="logo" src="../assets/logo.svg" alt="Hertzler" />
    <nav>
      <router-link :to="{ name: 'Dashboard' }">Dashboard</router-link>
      <router-link
        :to="{ name: 'Tickets' }"
        :class="{ 'router-link-active': $router.currentRoute.value.name == 'Ticket' }"
        >Tickets</router-link
      >
      <span
        :class="{ 'menu-header': true, 'router-link-active': $router.currentRoute.value.name == 'Monthly Report' }"
        @mouseover="submenuOn"
        @mouseleave="submenuOff"
      >
        Reports
        <div v-if="showSubmenu">
          <a @click="navigateToReport">Monthly Report</a>
        </div>
      </span>
      <router-link
        :to="{ name: 'Clients' }"
        :class="{ 'router-link-active': $router.currentRoute.value.name == 'Client' }"
        >Clients</router-link
      >
      <router-link :to="{ name: 'Settings' }">Settings</router-link>
    </nav>
    <img @click="open()" class="avatar" src="../assets/avatar.svg" alt="" />
  </header>
  <div v-if="showMenu" class="user-info">
    <span>{{ currentUser ? currentUser.username : null }}</span>
    <span @click="logoff">Log off</span>
  </div>
</template>

<script>
import global from '../composables/global';

export default {
  data() {
    return {
      showMenu: false,
      showSubmenu: false,
    };
  },
  setup() {
    return {
      state: global.state,
      currentUser: global.currentUser,
      logoff: global.logoff,
    };
  },
  methods: {
    open() {
      if (this.showMenu) {
        this.close();
        return;
      }
      this.showMenu = true;
      setTimeout(() => {
        document.getElementById('app').addEventListener('click', this.close, false);
      }, 250);
    },
    close() {
      this.showMenu = false;
      document.getElementById('app').removeEventListener('click', this.close, false);
    },
    submenuOn() {
      this.showSubmenu = true;
    },
    submenuOff() {
      this.showSubmenu = false;
    },
    navigateToReport() {
      this.$router.push({ name: 'Monthly Report' });
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  background: #dfdfdf;
  height: 92px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  .logo {
    padding-left: 25px;
    width: 200px;
  }
  nav {
    display: flex;
    flex-direction: row;
    padding-left: 40px;
    a,
    span {
      text-decoration: none;
      color: #707070;
      font-family: lato;
      font-size: 20px;
      padding: 20px;
      margin: auto;
      display: block;
    }
    .router-link-active {
      color: black;
      font-weight: bold;
    }
  }
  .avatar {
    width: 40px;
    margin-left: auto;
    margin-right: 35px;
    cursor: pointer;
  }
}

.user-info {
  position: absolute;
  right: 3px;
  top: 75px;
  border: 1px solid #3a3a3a;
  background: #dfdfdf;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  width: 150px;

  span {
    padding: 8px 12px;
    color: #3a3a3a;

    &:first-of-type {
      padding-bottom: 0px;
      font-style: italic;
    }
    &:last-of-type {
      cursor: pointer;
    }
  }
}

.menu-header {
  cursor: pointer;
  position: relative;

  div {
    border: 1px solid #3a3a3a;
    border-radius: 6px;
    position: absolute;
    top: 60px;
    background: #dfdfdf;
    width: auto;
    white-space: nowrap;
    box-shadow: 0 0 30px 2px #0000005b;
    a {
      color: #3a3a3a;
      padding: 8px 12px;
      font-size: 0.8em;
    }
    a:hover {
      background: #575757;
      color: #dfdfdf;
    }
  }
}
</style>
