<template>
  <div class="background">
    <div class="popup">
      <p>{{ state.alert.alertMessage }}</p>
      <div>
        <button @click="closeAlert" class="neutral">{{ state.alert.denyMessage }}</button>
        <button @click="confirmAlert" class="danger">{{ state.alert.confirmMessage }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import global from '../composables/global';

export default {
  setup() {
    return { state: global.state, closeAlert: global.closeAlert, confirmAlert: global.confirmAlert };
  },
};
</script>

<style lang="scss" scoped>
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 99vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.404);

  .popup {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -200px;
    background: #dfdfdf;
    width: 400px;
    height: 200px;
    border-radius: 6px;
    box-shadow: 0 0 50px 20px black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      color: #3a3a3a;
      font-size: 2em;
      text-align: center;
      padding-bottom: 10px;
      margin: 15px;
    }
    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      button {
        margin: 15px;
      }
    }
  }
}
</style>
