import axios from 'axios';
import { ref, readonly, computed } from 'vue';
import global from './global';

const intState: any = {
  tickets: [],
  sortedColumn: 'ticketnumber',
  sortDesc: false,
  search: '',
};

const state = ref(intState);

const compare = (a: any, b: any) => {
  if (state.value.sortDesc) {
    if (a[state.value.sortedColumn] < b[state.value.sortedColumn]) return 1;
    if (a[state.value.sortedColumn] > b[state.value.sortedColumn]) return -1;
  } else {
    if (a[state.value.sortedColumn] > b[state.value.sortedColumn]) return 1;
    if (a[state.value.sortedColumn] < b[state.value.sortedColumn]) return -1;
  }
  return 0;
};

const sortedTickets = computed(() => {
  let newTickets = state.value.tickets.sort(compare);
  newTickets = newTickets.filter((v: any) => {
    if (
      Object.values(v)
        .toString()
        .toLowerCase()
        .includes(state.value.search.toLowerCase())
    ) {
      return true;
    }
  });
  return newTickets;
});

const getTickets = () => {
  axios
    .get(global.serverString + 'tickets')
    .then((response) => {
      if (response.statusText != 'OK') throw 'status not OK';
      state.value.tickets = response.data;
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      console.log(err);
    });
};

const updateSearch = (newTerms: string) => {
  state.value.search = newTerms;
};

const changeSort = (newDirection: string) => {
  if (state.value.sortedColumn == newDirection) {
    state.value.sortDesc = !state.value.sortDesc;
  } else {
    state.value.sortedColumn = newDirection;
    state.value.sortDesc = false;
  }
};

export default { state: readonly(state), sortedTickets, getTickets, updateSearch, changeSort };
